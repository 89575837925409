import React, { FC } from 'react';
import { ChatHeaderProps } from './interfaces';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CloseSmall, Rearrangeable } from '@wix/wix-ui-icons-common/on-stage';

import s from './ChatHeader.scss';

export const ASSISTANT_HEADER_HOOKS = {
  close: 'close-ai-assistant',
};

export const DRAG_HANDLE_ID = 'aiAssistantDragHandle';

export const ChatHeader: FC<ChatHeaderProps> = ({ onClose, hideDragIcon }) => {
  const { t } = useTranslation();
  return (
    <div className={s.container} id={DRAG_HANDLE_ID}>
      <div className={s.headerLine}>
        {!hideDragIcon && <Rearrangeable className={s.dragIcon} />}
        <span className={s.headerAi}>{t('ai_assistant_header_1')}</span>
      </div>
      <div>
        <CloseSmall
          width={16}
          height={16}
          onClick={onClose}
          className={s.closeIcon}
          data-hook={ASSISTANT_HEADER_HOOKS.close}
        />
      </div>
    </div>
  );
};
